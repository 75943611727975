// required modules
import Service from '@/services/api/Service'
import McMiningCompanyModel from '@/models/MiningCompany/McMiningCompany'

/**
 * This class describes a McMiningCompany service.
 *
 * @class McMiningCompany (name)
 */
export default class McMiningCompanyService extends Service {

  /**
   * 
   */
  static create(mcMiningCompany) {
    return this.shape(mcMiningCompany).save()
  }

  /**
   * Define the McMiningCompany model.
   *
   * @return {Function} McMiningCompany resource.
   */
  static model() {
    return McMiningCompanyModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      .limit(-1)
      .get()
  }

  /**
   * 
   */
  static getBy(opts = {}) {
    return this
      .query()
      .page(opts.page)
      .limit(opts.limit)
      .paginate()
  }

  /**
   * 
   */
  static getById(mcMiningCompanyId) {
    return this.query().find(mcMiningCompanyId)
  }

  /**
   * 
   */
  static getAllPaginate() {
    return this
      .query()
      .param('get_all_results', true)
      .paginate()
  }

  /**
   * 
   */
  static logo(mcMiningCompany) {
    return mcMiningCompany.many('logo').transfer(mcMiningCompany.form(['image']))
  }

  /**
   * 
   */
  static newUser(payload) {
    return this.shape({ id: 'new_user' }).attach(payload)
  }  

  /**
   * 
   * @param {*} mcMiningCompany 
   */
  static toCreate() {
    return this.shape({
      rut: "",
      businessName: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      address: "",
      latitude: 0,
      longitude: 0,
    })
  }

  /**
   * 
   * @param {*} mcMiningCompany 
   */
  static toUser() {
    return this.shape({
      rut: "",
      name: "",
      email: "",
      password: "",
      miningId: null,
    })
  }

  /**
   * 
   */
  static update(mcMiningCompany) {
    return this.shape(mcMiningCompany).sync(mcMiningCompany)
  }
}