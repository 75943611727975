import McMiningCompanyService from '@/services/api/MiningCompany/McMiningCompany'
import RulesService from '@/services/rules'

export default {
  name: 'v-view-account',
  data() {
    return {
      propsSubmit:{
        color:'secondary',
        plain:false,
        text:false,
      },
      resetProps:{
        color:'primary',
        text:false,
      },
      busy: {
        company: false,
      },
      mcMiningCompany: {},
    }
  },
  methods: {

    /**
     * 
     */
    handleCompanyError(payload) {
      McMiningCompanyService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleCompanyReset() {
      this.busy.company = true
      this.mcMiningCompany = await McMiningCompanyService.getById(this.$user.profile.relatedId)
      this.busy.company = false
    },

    /**
     * 
     */
    async handleCompanySubmit() {
      this.busy.company = true
      await McMiningCompanyService.update(this.mcMiningCompany).then(this.handleCompanySuccess).catch(this.handleCompanyError)
      this.busy.company = false
    },

    /**
     * 
     */
    handleCompanySuccess(response) {
      McMiningCompanyService.translate({ response }).then(this.$toast.success)
    },
  },
  mounted() {
    this.handleCompanyReset()
  },
  props: {

    /**
     * 
     */
    addressRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(255),
      ],
    },

    /**
     * 
     */
    emailRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.email,
        RulesService.max(40),
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(200),
      ],
    },

    /**
     * 
     */
    passwordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },

    /**
     * 
     */
    phoneRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.length(9),
        RulesService.numeric,
      ],
    },

    /**
     * 
     */
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },
  },
}