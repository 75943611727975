// required modules
import Model from '@/models/Model'

/**
 * This class describes a McMiningCompany model.
 *
 * @class McMiningCompany
 */
export default class McMiningCompanyModel extends Model {

  /**
   * 
   */
  constructor(data) {
    super(data)

    // set only if has nested data
    this.longitude  = this.prop('address.longitude', 0)
    this.latitude   = this.prop('address.latitude', 0)

    //
    if (this.address && this.address.address) {
      this.address    = this.prop('address.address', '')
    }
  }

  /**
   * 
   */
  get defaultLogo() {
    return 'https://www.market-research-companies.in//images/default.jpg'
  }

  /**
   * 
   */
  get hasMediumLogo() {
    return this.prop('mediumLogo', '') !== ''
  }

  /**
   * 
   */
  get imgSrc() {
    return this.hasMediumLogo ? this.mediumLogoInBase64 : this.defaultLogo
  }

  /**
   * 
   */
  get mediumLogoInBase64() {
    return `data:image/jpeg;base64,${ this.mediumLogo }` 
  }

  /**
   * Define the McMiningCompany resource.
   *
   * @return {String} McMiningCompany endpoint name.
   */
  static resource() {
    return 'mining_company'
  }
}